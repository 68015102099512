import React from 'react';
import RenderConfigFE from '../components/render-config-fe';
import GeolocationAutocomplete from './geolocation-autocomplete';
import { IGeoAutocomplete } from 'src/_model/generic.model';
import { CustomerInfo } from '../../build-info';
import classNames from 'classnames';
import loadable from '@loadable/component';

const SliderStorySuccess = loadable(() => import('./story-success-slider'));

export interface IGeolocationSearchProps {
    formStep?: any;
    onSubmit: (item: IGeoAutocomplete) => void;
}

const GeolocationSearch = (props: IGeolocationSearchProps) => {
    const configStorySuccessSlider = CustomerInfo.storySuccessSlider;

    console.log('configStorySuccessSlider:', configStorySuccessSlider);

    const classColSearch = classNames({
        'col-sm-12 col-md-8 col-lg-8': configStorySuccessSlider === 'large',
        'col-sm-12 col-md-6 col-lg-6': configStorySuccessSlider === 'small'
    });

    const classColImage = classNames({
        'col-sm-12 col-md-4 col-lg-4': configStorySuccessSlider === 'large',
        'col-sm-12 col-md-6 col-lg-6': configStorySuccessSlider === 'small'
    });

    return (
        <div className="row search">
            <div className={`${classColSearch} o-relative`}>
                <div className="custom-text custom">
                    <RenderConfigFE
                        className="title"
                        name={props.formStep ? props.formStep.ContactTitle : 'ContactTitle1'}
                        type="html"
                    />
                    <RenderConfigFE
                        name={props.formStep ? props.formStep.ContactDescription : 'ContactDescription1'}
                        type="html"
                    />
                </div>
                <div className="custom-text-autocomplete">
                    <GeolocationAutocomplete
                        onSelect={(item: IGeoAutocomplete) => {
                            props.onSubmit(item);
                        }}
                    />
                </div>
            </div>
            <div className={classColImage}>
                {configStorySuccessSlider === 'large' && <SliderStorySuccess />}
                {configStorySuccessSlider === 'small' && (
                    <RenderConfigFE name="ContactImage" type="image" className="contact-image custom" />
                )}
            </div>
        </div>
    );
};

export default GeolocationSearch;
