import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import RenderConfigFE from '../../../src/components/render-config-fe';
import LazyLoad from 'react-lazyload';
import ReviewSlider from '../../../src/views/review-slider-common';
import ContactCard from '../../../src/views/contact-card';
import { getConfigFrontEnd } from '../../../src/components/use-config-fe';


const IndexPage = () => {
    const BackgroundImage = getConfigFrontEnd({ name: 'BackgroundImage' });
    const BackdropImage_2 = getConfigFrontEnd({ name: 'BackdropImage_2' });
    const style = {
        background: `url(${BackgroundImage.url})`
    };
    const style_2 = {
        height: '335px',
        background: `url(${BackdropImage_2.url})`,
        borderTop: '2px solid #8B0000',
        borderBottom: '2px solid #8B0000'
    };
    return (
        <Layout>
            <SEO title="Boes & Boes" />

            <section className="section section-content custom" style={style}>
                <ContactCard />
            </section>

            <LazyLoad height={400} offset={50}>
                <section className="section section-review">
                    <div className="review container-fluid">
                        <ReviewSlider />
                    </div>
                </section>
            </LazyLoad>

            <LazyLoad height={400} offset={100}>
                <section className="section section-article custom">
                    <div className="container-fluid" style={{ maxWidth: '1400px', textAlign: 'center' }}>
                        <RenderConfigFE className="title" name="FooterTitle" type="html" />
                        <RenderConfigFE name="FooterContent2" type="html" />
                    </div>
                </section>
            </LazyLoad>

            <section className="section section-content custom" style={style_2}></section>
        </Layout>
    );
};

export default IndexPage;
