import React, { useState } from 'react';
import { IGeoAutocomplete } from 'src/_model/generic.model';
import RenderConfigFE from '../components/render-config-fe';
import loadable from '@loadable/component';
import GeolocationSearch from './geolocation-search';

const ContactFormLazy = loadable(() => import('./contact-form'));

export interface IContactCardProps {
    formStep1?: any;
    formStep2?: any;
    children?: any;
    policy?: any;
}

const ContactCard = (props: IContactCardProps) => {
    const [stepActive, setStepActive] = useState<number>(1);
    const [itemSearch, setItemSearch] = useState<IGeoAutocomplete>({ description: '', reference: '' });

    return (
        <div id="free-estimation" className="free-estimation container-fluid">
            <div className="fluid is-always-shadow contact-card custom">
                {stepActive === 1 && (
                    <GeolocationSearch
                        onSubmit={(item: IGeoAutocomplete) => {
                            setStepActive(2);
                            setItemSearch(item);
                        }}
                        formStep={props.formStep1}
                    />
                )}
                {stepActive === 2 && (
                    <ContactFormLazy
                        item={itemSearch}
                        onSubmit={(item: IGeoAutocomplete) => {
                            setStepActive(3);
                        }}
                        formStep={props.formStep2}
                        policy={props.policy}
                    />
                )}
                {stepActive === 3 && <RenderConfigFE name="SendMailSuccess" type="html" />}
            </div>
        </div>
    );
};

export default ContactCard;
