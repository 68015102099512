import React, { useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IFrontEndConfig, IReview } from 'src/_model/airtable.model';
// import RenderConfigFE from '../components/render-config-fe';
import ReviewCard from '../components/review-card';
import ArrowLeftIcon from '../components/arrow-left-icon';
import ArrowRightIcon from '../components/arrow-right-icon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import loadable from '@loadable/component';

const SliderLazy = loadable(() => import('react-slick'));

const Slider = React.forwardRef((props: any, ref) => <SliderLazy ref={ref} {...props} />);

export interface IReviewSliderProps {
    children?: any;
}

export interface IReviewQueryData {
    configs: {
        nodes: IFrontEndConfig[];
    };
    reviews: {
        nodes: IReview[];
    };
}

const ReviewSlider = (props: IReviewSliderProps) => {
    const data = useStaticQuery<IReviewQueryData>(graphql`
        query {
            configs: allAirtableConfigFe(filter: { Name: { eq: "SliderReview" } }) {
                nodes {
                    Name
                    Type
                    Value
                    id
                }
            }
            reviews: allAirtableReview {
                nodes {
                    Active
                    CustomerName
                    PersonName
                    PersonPictureUrl
                    ReviewSourceName
                    ReviewText
                    ReviewDate
                    Score
                    id
                    ReviewSourcePicture {
                        filename
                        id
                        size
                        thumbnails {
                            small {
                                height
                                url
                                width
                            }
                        }
                        type
                        url
                    }
                    PersonPicture {
                        filename
                        id
                        size
                        type
                        url
                        thumbnails {
                            small {
                                height
                                url
                                width
                            }
                        }
                    }
                    PropertyPicture {
                        id
                        filename
                        size
                        type
                        url
                        thumbnails {
                            small {
                                height
                                url
                                width
                            }
                        }
                    }
                    SmallReviewStoryPicture {
                        filename
                        size
                        id
                        type
                        url
                        thumbnails {
                            small {
                                height
                                url
                                width
                            }
                        }
                    }
                }
            }
        }
    `);

    let sliderRef = useRef(null);

    const configReviewSlider = JSON.parse(data.configs.nodes[0].Value);
    const reviews = data.reviews.nodes;
    const [slides, setSlides] = useState<IReview[]>(reviews.slice(0, 5));

    const handleAddSlides = () => {
        if (slides.length !== reviews.length) setSlides(reviews);
    };

    const handlePrevSlide = (event: React.MouseEvent<HTMLButtonElement>) => {
        // @ts-ignore
        sliderRef.current!.slickPrev();
    };

    const handleNextSlide = (event: React.MouseEvent<HTMLButtonElement>) => {
        // @ts-ignore
        sliderRef.current!.slickNext();
    };

    const settingSlider = {
        arrows: configReviewSlider.arrows,
        dots: configReviewSlider.dots,
        autoplay: configReviewSlider.autoPlay,
        autoplaySpeed: configReviewSlider.autoPlaySpeed,
        centerMode: configReviewSlider.centerMode,
        initialSlide: configReviewSlider.initialSlide,
        slidesToScroll: configReviewSlider.slidesToScroll,
        slidesToShow: configReviewSlider.slidesToShow,
        speed: configReviewSlider.speed,
        variableWidth: configReviewSlider.variableWidth,
        responsive: configReviewSlider.responsive,
        infinite: true,
        lazyload: true,
        afterChange: (currentSlide: number) => {
            handleAddSlides();
        }
    };

    return (
        <>
            <button
                aria-label="slide-prev"
                className="btn-custom btn-icon btn-circle custom-prev"
                style={{ marginRight: '5px' }}
                onClick={handlePrevSlide}>
                <ArrowLeftIcon fill="none" />
            </button>
            <button
                aria-label="slide-next"
                className="btn-custom btn-icon btn-circle custom-next"
                onClick={handleNextSlide}>
                <ArrowRightIcon fill="none" />
            </button>
            <Slider ref={sliderRef} {...settingSlider}>
                {slides.map((review: IReview, index: number) => (
                    <div key={index} className="item">
                        <ReviewCard item={review} id={index} />
                    </div>
                ))}
            </Slider>
        </>
    );
};

export default ReviewSlider;
